import "./Search.css";
import search from "../../assets/search.svg";
import { useState, useEffect } from "react";

function Search() {
  const [searchData, setSearchData] = useState();

  return (
    <div className="Search">
      <div className="search-inner">
        <img className="search-img" src={search}></img>
        <input
          className="search-text"
          placeholder="Search"
          type="search"
          name="search-form"
          id="search-form"
          onChange={(e) => setSearchData(e.target.value)}
        />
      </div>
      <h1>{searchData} </h1>
    </div>
  );
}

export default Search;
